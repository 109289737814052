





























































import { Component, Vue } from "vue-property-decorator";
import { RequestPaging } from "@/utils/util";
import {
    apiRoleList,
    apiRoleDelete,
    apiSupplierlists,
    apiSupplierDelete,
    apiShoplists,
    apiShopDelete,
} from "@/api/setting/permissions";
import LsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { PageMode } from "@/utils/type";

@Component({
    components: {
        LsDialog,
        LsPagination,
    },
})
export default class Role extends Vue {
    /** S Data **/
    pager: RequestPaging = new RequestPaging();
    api_type: any = "platform";
    /** E Data **/

    /** S Methods **/
    // 添加角色
    onRoleAdd(): void {
        if (this.$route.query.ccid) {
            this.$router.push({
                path: "/platform-permission/permissions/role_edit",
                query: {
                    mode: PageMode.ADD,
                    ccid: this.$route.query.ccid,
                    AppAuth: this.$route.query.AppAuth,
                },
            });
        } else {
            this.$router.push({
                path: "/setting/permissions/role_edit",
                query: { mode: PageMode.ADD },
            });
        }
    }

    // 编辑角色
    onRoleEdit(item: any): void {
        if (this.$route.query.ccid) {
            this.$router.push({
                path: "/platform-permission/permissions/role_edit",
                query: {
                    mode: PageMode.EDIT,
                    id: item.id,
                    ccid: this.$route.query.ccid,
                    AppAuth: this.$route.query.AppAuth,
                },
            });
        } else {
            this.$router.push({
                path: "/setting/permissions/role_edit",
                query: { mode: PageMode.EDIT, id: item.id },
            });
        }
    }

    // 删除角色
    onRoleDel(data: any): void {
        if (this.api_type === "shop") {
            apiShopDelete({
                id: data.id,
                sid: localStorage.getItem("shopId"),
            })
                .then(() => {
                    this.getRoleList(1);
                    this.$message.success("删除成功!");
                })
                .catch(() => {
                    this.$message.error("删除失败!");
                });
        }
        if (this.api_type === "supplier") {
            apiSupplierDelete({
                id: data.id,
                sid: localStorage.getItem("supplierId"),
            })
                .then(() => {
                    this.getRoleList(1);
                    this.$message.success("删除成功!");
                })
                .catch(() => {
                    this.$message.error("删除失败!");
                });
        }
        if (this.api_type === "platform") {
            apiRoleDelete({
                id: data.id,
            })
                .then(() => {
                    this.getRoleList(1);
                    this.$message.success("删除成功!");
                })
                .catch(() => {
                    this.$message.error("删除失败!");
                });
        }
    }

    // 获取数据
    getRoleList(page?: number) {
        page && (this.pager.page = page);
        if (this.api_type === "shop") {
            this.pager
                .request({
                    callback: apiShoplists,
                    params: {
                        sid: localStorage.getItem("shopId"),
                    },
                })
                .catch(() => {
                    this.$message.error("数据请求失败，刷新重载!");
                });
        }
        if (this.api_type === "supplier") {
            this.pager
                .request({
                    callback: apiSupplierlists,
                    params: {
                        sid: localStorage.getItem("supplierId"),
                    },
                })
                .catch(() => {
                    this.$message.error("数据请求失败，刷新重载!");
                });
        }
        if (this.api_type === "platform") {
            this.pager
                .request({
                    callback: apiRoleList,
                })
                .catch(() => {
                    this.$message.error("数据请求失败，刷新重载!");
                });
        }
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.api_type = localStorage.getItem("api_type");
        if (this.$route.path == "/setting/permissions/role") {
            this.api_type = "platform";
            localStorage.setItem("api_type", "platform");
            localStorage.removeItem("supplierId");
            localStorage.removeItem("shopId");
        }
        this.getRoleList(1);
    }
    /** E Life Cycle **/
}
